import React, {
  forwardRef,
  useRef,
  useEffect,
  createContext,
  useContext,
} from 'react';
import {
  Autocomplete,
  Box,
  IconButton,
  Typography,
  createFilterOptions,
} from '@mui/material';
import { Refresh } from '@mui/icons-material';
import { Controller } from 'react-hook-form';
import { VariableSizeList } from 'react-window';
import { DropsContext } from 'contexts/DropsContext';
import Input from 'components/Input';
import styles from './styles';

const renderRow = ({ data, index, style }) => {
  const dataSet = data[index];
  return (
    <Box
      {...dataSet[0]}
      key={dataSet[1]?.value}
      sx={styles?.optionContainer(style)}
    >
      <Typography sx={styles?.optionLabel} variant="body1" noWrap>
        {dataSet[1]?.label}
      </Typography>
      {Object?.keys(dataSet[1])
        ?.filter(
          (f) =>
            Boolean(dataSet[1][f]) &&
            f?.substring(0, 1) === f?.substring(0, 1)?.toUpperCase()
        )
        ?.map((o, i) => (
          <Typography
            key={i?.toString()}
            sx={styles?.optionSubLabel}
            variant="body2"
          >
            {o}: {dataSet[1][o]}
          </Typography>
        ))}
    </Box>
  );
};

const OuterElementContext = createContext({});

const OuterElementType = forwardRef((props, ref) => {
  const outerProps = useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

const useResetCache = (data) => {
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
};

const ListboxComponent = forwardRef(({ children, ...other }, ref) => {
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });
  const itemCount = itemData.length;

  const count = Math.max.apply(
    Math,
    itemData?.map(
      (d) =>
        Object?.keys(d[1])?.filter(
          (f) => f?.substring(0, 1) === f?.substring(0, 1)?.toUpperCase()
        )?.length
    )
  );

  const itemSize = 36 + 20 * count;
  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(() => itemSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight()}
          ref={gridRef}
          outerElementType={OuterElementType}
          itemSize={() => itemSize}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const VirtualDrop = ({
  control,
  name,
  options = [],
  label,
  onValueChange,
  refresh,
  ...props
}) => {
  const { getDrops } = useContext(DropsContext);

  const onRefresh = () => getDrops([refresh]);

  const filterOptions = createFilterOptions({
    stringify: (option) =>
      Object?.keys(option)
        ?.filter(
          (f) =>
            f?.substring(0, 1) === f?.substring(0, 1)?.toUpperCase() ||
            f === 'label'
        )
        ?.map((key) => option[key])
        ?.join(''),
  });

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Box sx={styles?.container}>
          <Autocomplete
            {...props}
            clearOnEscape
            disableListWrap
            sx={{ ...styles?.drop, ...props?.sx }}
            options={options || []}
            value={field?.value}
            filterOptions={filterOptions}
            ListboxComponent={ListboxComponent}
            renderInput={(params) => (
              <Input {...params} {...field} label={label} onChange={() => {}} />
            )}
            renderOption={(props, option, state) => [
              props,
              option,
              state.index,
            ]}
            renderGroup={(params) => params}
            onChange={(_, data) => {
              if (!!props?.multiple) {
                field?.onChange(data?.map((d) => d.value || d));
                if (!!onValueChange) {
                  onValueChange(data?.map((d) => d.value || d));
                }
              } else {
                field?.onChange(data?.value || null);
                if (!!onValueChange) {
                  onValueChange(data || null);
                }
              }
            }}
            getOptionLabel={(option) => {
              if (typeof option !== 'object') {
                const ret = options?.find((c) => c.value === option);
                return option?.label || ret?.label || '';
              }
              return option.label || '';
            }}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
          {Boolean(refresh) && (
            <IconButton size="small" onClick={onRefresh}>
              <Refresh fontSize="small" />
            </IconButton>
          )}
        </Box>
      )}
    />
  );
};

export default VirtualDrop;
