import React from 'react';
import { DialogActions, DialogContent, Typography } from '@mui/material';
import { ReportProblem } from '@mui/icons-material';
import { useModal } from 'components/Modals';
import Button from 'components/Button';

const DuplicidadeModal = ({ onSubmit }) => {
  const { closeModal } = useModal();

  const onConfirm = () => {
    onSubmit();
    closeModal();
  };

  return (
    <>
      <DialogContent sx={{ textAlign: 'center' }}>
        <ReportProblem color="primary" sx={{ fontSize: 150 }} />
        <Typography variant="h6">Item em duplicidade</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" variant="contained">
          Adicionar mesmo assim
        </Button>
        <Button onClick={closeModal} color="secondary" variant="contained">
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default DuplicidadeModal;
